/*
    A model describing tag commander dataLayer structure
    Is stored into $window.sessionStorage to be persistent
*/
webClient.factory('tcModel', ['$window', 'tcProduct', 'scopeParser', function ($window, tcProduct, scopeParser) {

    tcModel.getPageName = function (key) {

        var dictionnary = new Map();
        dictionnary.set('/Flightsearch/Error', 'erreur_resavol');
        dictionnary.set('/Flightsearch/Payment/CB', 'tunnel_resavol::paiement_resavol');
        dictionnary.set('/Flightsearch/BookingOK', 'tunnel_resavol::confirmation_site_resavol_OK');
        dictionnary.set('/Flightsearch/BookingKO', 'tunnel_resavol::confirmation_site_resavol_KO');
        dictionnary.set('/Flightsearch/Wait', 'moteur_resavol::attente_resultats_resavol');
        dictionnary.set('/Flightsearch/Payment/CB', 'tunnel_resavol::paiement_resavol');
        dictionnary.set('/Flightsearch/Bookingwait', 'tunnel_resavol::attente_confirmation_site_resavol');
        dictionnary.set('/Flightsearch/Payment/CBwait', 'tunnel_resavol::attente_paiement_resavol');
        dictionnary.set('/Flightsearch/Payment/CBwait', 'tunnel_resavol::attente_paiement_resavol');
        dictionnary.set('/Flightsearch/Result', 'moteur_resavol::resultats_recherche_resavol');
        dictionnary.set('/Flightsearch/Bookingwait', 'tunnel_resavol::attente_confirmation_site_resavol');
        dictionnary.set('/Flightsearch/Purchase1', 'tunnel_resavol::infos_voyageur_resavol');
        dictionnary.set('/Flightsearch/Purchase2', 'tunnel_resavol::infos_assurances_resavol');
        dictionnary.set('/Flightsearch/Purchase3', 'tunnel_resavol::infos_payeur_resavol');
        dictionnary.set('step_4', 'tunnel_resavol::infos_payeur_resavol');
        dictionnary.set('/Search', 'recherche_resavolmb');

        return dictionnary.get(key);
    }




    // Environment
    tcModel.prototype.env_work;
    tcModel.prototype.env_language;
    tcModel.prototype.env_product;
    tcModel.prototype.env_currency;
    // Users
    tcModel.prototype.user_id;
    tcModel.prototype.user_segment;
    // Tree structure
    tcModel.prototype.page_name;
    tcModel.prototype.page_type;
    // Funnel information
    tcModel.prototype.basket_id;
    tcModel.prototype.order_id;
    tcModel.prototype.order_amount;
    tcModel.prototype.order_amount_ati;
    tcModel.prototype.order_discount_tf;
    tcModel.prototype.order_newcustomer;
    tcModel.prototype.order_tax;
    tcModel.prototype.order_payment_method;
    tcModel.prototype.order_status;
    tcModel.prototype.order_promo_code;
    tcModel.prototype.order_products;


    function tcModel() {
        
        this.page_type = '7-3-1';
        this.env_product = 'vol';
        this.env_currency = 'EUR';
        this.order_newcustomer = '1';
        this.order_products = [];
    }

    tcModel.prototype.set = function (property, value) {
        if (value) {
            this[property] = value;
        }
    }

    return tcModel;

}]);