/*
    A model used to embed GTM product, inside datalayer.
    It also deals with NG scope object parsing 
*/
webClient.factory('tcProduct', function () {

    tcProduct.prototype.order_product_category;
    tcProduct.prototype.order_product_id;
    tcProduct.prototype.order_product_quantity;
    tcProduct.prototype.order_product_unitprice_ati;
    tcProduct.prototype.order_product_unitprice_tf;
    tcProduct.prototype.order_product_origin;
    tcProduct.prototype.order_product_destination;
    tcProduct.prototype.order_product_passengers;

    function tcProduct() {
        this.order_product_category = '2[voyage]';
        this.order_product_id = '1[vol]'; 
    }

    tcProduct.prototype.set = function (property, value) {
        if (value) {
            this[property] = value;
        }
    }

    return tcProduct;

});