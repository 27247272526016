/*
    A service used to trigger GTM pushes Carrefour
*/
webClient.factory('trackingService', ['$window', 'tcModel', 'tcProduct', 'scopeParser',
    function ($window, tcModel, tcProduct, scopeParser) {
        return {

            isActivated: function () {
                return typeof $window.dataLayer !== 'undefined' && $window.dataLayer != null;
            },

            initDataLayer: function (name) {

            },

            /*
                Reload data using JSON stored in window.sessionStorage
                The action name / value is used as key 
            */
            loadSessionData: function (action, value) {
                return null;

            },

            set: function (object, property, value) {
                console.log('SET ' + property + " - " + value);
                if (value) {
                    object[property] = value;
                }
            },

            /*
                Use to send page views. Distinct gtm object in order not to mess with ecommerce tunnel
            */
            pushPageData: function (page, title, language, fireEvent, args) {
                console.log("---Carrefour track ---> pushPageData ::  " + page);

                console.info("============== show the pushPageData ============");

            },

            addProduct: function (product) {

                console.log("add product");
                console.log(product);
                /*
                var interactionEvent = {};
                interactionEvent.event = 'add_to_carts';
                interactionEvent.currency = 'EUR';
                interactionEvent.checkout_step = 1;
                var bkgData = $window.$scope.bkgData;
                this.fillInteractionEvent(bkgData, null, interactionEvent);
                */
            },

            removeProduct: function (id) {

            },

            setOption: function (value) {

            },

            parseScope: function (value) {

            },

            getCookie: function getCookie(cname) {
                var name = cname + "=";
                var ca = document.cookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            pageViewEvent: function (pageType, page) {
                if (this.isDP())
                    this.pageViewEventDP(pageType, page);
                else
                    this.pageViewEventFlight(pageType, page);
            },

            pageViewEventFlight: function (pageType, page) {
                console.log("---Carrefour Flight track ---> page_data Event ::  " + pageType);
                var pageViewEvent = {
                    'event': 'pageview',

                    /*
                    'user_ID': '',
                    'user_gender': '',
                    'user_category': 'N',
                    'user_lifeTimeValue': '0',
                    'user_age': '0',
                    'user_email': '',
                    'user_postalCode': '00000',
                    'user_lastOrderDate': '',
                    'user_newsletterPartner': 'N',
                    'user_city': '',
                    'user_newsletterVoyages': 'N',
                    */

                    'page_template': page,
                    'page_category': 'Vol',
                    'page_name': 'vol',

                    'env_referrer': document.referrer,
                    'env_country': window.lng.split('-').first(),
                    'env_language': window.lng.split('-').first(),
                    'env_work': document.location.href.indexOf('.mrvol') == -1 ? 'prod' : 'preprod',
                    'env_device': navigator.userAgentData.mobile ? 'mobile' : 'desktop',
                }


                //Custom event page
                if (pageType == "error_page") {
                    pageViewEvent.page_errorCode = 500;
                    pageViewEvent.page_errorMessage = page;
                }

                if (pageType == "search_result_page") {
                    var summary = $window.$scope.requestSummary;
                    pageViewEvent.page_name = 'listeVolc';
                    pageViewEvent.page_step = 1;
                    pageViewEvent.search_tabTitle = "ccc";
                    pageViewEvent.search_departureDate = summary.itineraries[0].goDate.dateString;
                    pageViewEvent.search_departureFlexibility = "";

                    if (summary.itineraries.length >= 2) {
                        pageViewEvent.search_ReturningDate = summary.itineraries[1].goDate.dateString;
                        pageViewEvent.search_duration = parseInt((new Date(summary.itineraries[1].goDate.date) - new Date(summary.itineraries[0].goDate.date)) / (1000 * 60 * 60 * 24));
                    }
                    pageViewEvent.search_departureCity = summary.itineraries[0].origin.cityName;
                    pageViewEvent.search_arrivalCity = summary.itineraries[0].destination.cityName;
                    pageViewEvent.search_travelersAdult = summary.adults;
                    pageViewEvent.search_travelersChild = summary.childs;
                    pageViewEvent.search_travelersBaby = summary.infants;
                    pageViewEvent.search_childrenAge = "";
                    pageViewEvent.search_resultsNumber = $window.$scope.view.totalProductsCount;
                    pageViewEvent.search_budgetMax = ""; 
                    pageViewEvent.search_modifiedFlights = false;
                    pageViewEvent.search_stopovers = "Indifférent";
                    pageViewEvent.search_luggage = "";
                    pageViewEvent.search_flightScheduleRange = false;
                    pageViewEvent.search_selectedAirline = false;
                    pageViewEvent.search_selectedAirport = false;
                    pageViewEvent.search_flightClass = scopeParser.getCabinClassName(summary.cabinClass);
                    pageViewEvent.search_flightType = this.getFlightTypeName(summary.itineraries);
                }

                //P1
                if (pageType == "summary_page") {
                    console.log("summary_page $window.$scope.bkgData");
                    console.log($window.$scope.bkgData);

                    var bkgData = $window.$scope.bkgData;
                    pageViewEvent.page_name = 'bookingVol';
                    pageViewEvent.page_step = 2;
                    pageViewEvent.page_productsList = [this.getProduct(bkgData, null)];

                    pageViewEvent.page_productsList[0].product_insuranceAmount = "";
                    pageViewEvent.page_productsList[0].product_price_folder = "";
                }

                //P4
                if (pageType == "checkout_page") {
                    console.log("checkout_page $window.$scope.bkgData");
                    console.log($window.$scope.bkgData);

                    var bkgData = $window.$scope.bkgData;

                    pageViewEvent.page_name = 'paiementVol';
                    pageViewEvent.page_step = 3;
                    pageViewEvent.page_productsList = [this.getProduct(bkgData, null)];
                    //pageViewEvent.page_productsList[0].product_insuranceAmount = "";
                    //pageViewEvent.page_productsList[0].product_price_folder = "";
                }



                //P5
                if (pageType == "transaction_page") {
                    console.log("transaction_page $window.$scope.bkgData");
                    console.log($window.$scope.bkgData);
                    var bkgData = $window.$scope.bkgData;

                    pageViewEvent.page_name = 'confirmation';
                    pageViewEvent.page_step = 4;
                    pageViewEvent.page_productsList = [this.getProduct(bkgData, null)];
                    pageViewEvent.isNewClient = true;
                    console.log("--fillTransactionInfo--");
                    this.fillTransactionInfo(bkgData, null, pageViewEvent);

                }

                console.info("============== show the pageViewEvent ============");
                console.info(pageViewEvent);

                $window.dataLayer.push(
                    pageViewEvent
                );
            },
            pageViewEventDP: function (pageType, page) {

                console.log("---Carrefour DP track ---> page_data Event ::  " + pageType);
                //$window.dataLayer.push({ 'page_data': null });
                var pageViewEvent = {
                    'event': 'pageview',

                    /*
                    'user_ID': '',
                    'user_gender': '',
                    'user_category': 'N',
                    'user_lifeTimeValue': '0',
                    'user_age': '0',
                    'user_email': '',
                    'user_postalCode': '00000',
                    'user_lastOrderDate': '',
                    'user_newsletterPartner': 'N',
                    'user_city': '',
                    'user_newsletterVoyages': 'N',
                    */

                    'page_template': page,
                    'page_category': 'DP',
                    'page_name': 'vol',

                    //'page_errorCode': 0,
                    //'page_errorMessage': '',
                    //'page_step': 0,
                    //'page_productsList': [],

                    'env_referrer': document.referrer,
                    'env_country': window.lng.split('-').first(),
                    'env_language': window.lng.split('-').first(),
                    'env_work': document.location.href.indexOf('.mrvol') == -1 ? 'prod' : 'preprod',
                    'env_device': navigator.userAgentData.mobile ? 'mobile' : 'desktop',
                }

                //Custom event page
                if (pageType == "error_page") {
                    pageViewEvent.page_errorCode = 500;
                    pageViewEvent.page_errorMessage = page;
                }

                //P1
                if (pageType == "summary_page") {
                    console.log("summary_page $window.$scope.bkgData");
                    console.log($window.$scope.bkgData);
                    console.log($window.$scope.bkgData.accommodations[0]);

                    var accommodation = $window.$scope.bkgData.accommodations[0];
                    var bkgData = $window.$scope.bkgData;
                    pageViewEvent.page_name = 'vol';
                    pageViewEvent.page_step = 2;
                    pageViewEvent.page_productsList = [ this.getProduct(bkgData, accommodation) ];

                    pageViewEvent.page_productsList[0].product_insuranceAmount = "";
                    pageViewEvent.page_productsList[0].product_price_folder = "";
                }

                //P4
                if (pageType == "checkout_page") {
                    console.log("checkout_page $window.$scope.bkgData");
                    console.log($window.$scope.bkgData);
                    console.log($window.$scope.bkgData.accommodations[0]);

                    var accommodation = $window.$scope.bkgData.accommodations[0];
                    var bkgData = $window.$scope.bkgData;

                    pageViewEvent.page_name = 'paiement';
                    pageViewEvent.page_step = 3;
                    pageViewEvent.page_productsList = [ this.getProduct(bkgData, accommodation) ];
                    pageViewEvent.page_productsList[0].product_insuranceAmount = "";
                    pageViewEvent.page_productsList[0].product_price_folder = "";
                }



                //P5
                if (pageType == "transaction_page") {
                    console.log("transaction_page $window.$scope.bkgData");
                    console.log($window.$scope.bkgData);
                    console.log($window.$scope.bkgData.accommodations[0]);

                    var accommodation = $window.$scope.bkgData.accommodations[0];
                    var bkgData = $window.$scope.bkgData;

                    pageViewEvent.page_name = 'confirmation';
                    pageViewEvent.page_step = 4;
                    pageViewEvent.page_productsList = [this.getProduct(bkgData, accommodation) ];
                    pageViewEvent.isNewClient = true;

                    this.fillTransactionInfo(bkgData, accommodation, pageViewEvent);

                }

                if (pageType == "change_flight") {
                    console.log("change_flight $window.$scope.bkgData");
                    console.log($window.$scope.bkgData);

                    var bkgData = $window.$scope.bkgData;

                    pageViewEvent.page_step = 0;
                    //pageViewEvent.page_productsList = [{ "product": this.getProduct(bkgData, null) }];

                }

                console.info("============== show the pageViewEvent ============");
                console.info(pageViewEvent);

                $window.dataLayer.push(
                    pageViewEvent
                );

            },

            fillTransactionInfo: function (bkgData, accommodation, pageView) {
                const urlParams = new URLSearchParams($window.location.search);
                const paymentCard = urlParams.get('selectedpayment');

                pageView.order_ID = bkgData.bookingResponse.numbers[0].bookingNumber;
                pageView.order_bookingID = bkgData.bookingResponse.numbers[0].bookingNumber;
                pageView.order_price = bkgData.totalBasketPrice();
                pageView.order_adminFee = 0;
                if (bkgData.bookingRequest.discountCode && bkgData.discountInfo) {
                    pageView.order_promotionCode = bkgData.bookingRequest.discountCode;
                    pageView.order_promotionAmount = bkgData.discountInfo.applicableAmount;
                } else {
                    pageView.order_promotionCode = "";
                    pageView.order_promotionAmount = 0;
                }
                pageView.order_taxes = scopeParser.tryGetTaxAmount();
                pageView.order_amount_wt_ass_folder_and_taxes = bkgData.totalBasketPrice() - scopeParser.tryGetTaxAmount();
                pageView.order_amount = bkgData.totalBasketPrice();

                var bags = basketHelper.getAdditionalBagsPriceDetails(window.$scope.siteType, bkgData.bookingRequest.passengers);
                console.log("bag :::");
                console.log(bags);
                if (bags[0].totalPrice == 0) {
                    bags = [];
                }

                var seatmapObj = JSON.parse(window.sessionStorage.getItem('selectedSeats_' + window.$scope.bkgData.purchaseResponse.purchaseId));
                var seatmap = [];

                if (seatmapObj != null) {
                    seatmap = basketHelper.getSeatMapPriceDetails(window.$scope.siteType, seatmapObj);
                }

                var inssurance = [];
                if (bkgData.bookingRequest.insurance != null) {
                    inssurance = basketHelper.getInsurancePriceDetails(window.$scope.siteType, bkgData.bookingRequest.insurance, bkgData.purchaseResponse.proposalsRequested.insurances);
                }
                if (inssurance.any()) {
                    pageView.order_insuranceFee = inssurance[0].totalPrice;
                    pageView.order_insurance = true;
                }
                else {
                    pageView.order_insuranceFee = 0;
                    pageView.order_insurance = false;
                }


                var optionNumber = 0;
                if (inssurance.any()) optionNumber++;
                if (bags.any() && bags[0].totalPrice != 0) optionNumber++;
                if (seatmapObj != null) optionNumber++;
                pageView.order_optionsQuantity = optionNumber;

                pageView.order_optionsFee = this.getPriceArray(seatmap) + this.getPriceArray(inssurance) + this.getPriceArray(bags);
                if (accommodation != null) {
                    pageView.order_duration = accommodation.nightNumber;
                } else {
                    if (bkgData.purchaseResponse.itineraries.length >= 2) {
                        pageView.order_duration = parseInt((new Date(bkgData.purchaseResponse.itineraries[1].date) - new Date(bkgData.purchaseResponse.itineraries[0].date)) / (1000 * 60 * 60 * 24)) + 1;
                    }
                }

                pageView.order_paymentType = paymentCard;
                
                pageView.order_luggage = bags.any() ? true : false;
                pageView.order_luggage_number = scopeParser.retrieveLuggagesSelectedNumber();
                pageView.order_user_email = this.sha256(bkgData.bookingRequest.contact.mail);
            },

            getPriceArray: function (val) {
                if (val == null) {
                    return 0
                }
                if (!val.any()) {
                    return 0
                }
                return val[0].totalPrice;
            },
            ecommerceEvent: function (success) {
                if (this.isDP())
                    this.ecommerceEventDP(success);
                else
                    this.ecommerceEventFlight(success);
            },
            ecommerceEventFlight: function (success) {
                console.log("---Carrefour Flight track après la confirmation  de paiement, n'envoyer que la première fois ---> ecommerce Event ::  " + success);

                var interactionEvent = {};
                var bkgData = $window.$scope.bkgData;

                //Check if payment is ok or not
                if (success) {

                    interactionEvent.event = 'purchase';
                    interactionEvent.currency = 'EUR';
                    interactionEvent.checkout_step = 4;
                    this.fillInteractionEvent(bkgData, null, interactionEvent);
                    interactionEvent.insuranceSelected = bkgData.bookingRequest.insurance != null;
                    if (bkgData.bookingRequest.insurance != null)
                        interactionEvent.insurance_value = bkgData.purchaseResponse.proposalsRequested.insurances[0].totalPrice;
                    else
                        interactionEvent.insurance_value = 0;
                } else {
                    interactionEvent.event = 'payment_error';
                    interactionEvent.currency = 'EUR';
                    interactionEvent.checkout_step = 4;

                }
                const urlParams = new URLSearchParams(window.location.search);
                const paymentType = urlParams.get('paymenttype');
                interactionEvent.payment_method = paymentType;

                console.info("============== show the ecommerceEvent ============");
                console.info(interactionEvent);

                $window.dataLayer.push(
                    interactionEvent
                );
            },
            ecommerceEventDP: function (success) {
                console.log("---Carrefour DP track après la confirmation  de paiement, n'envoyer que la première fois ---> ecommerce Event ::  " + success);

                var interactionEvent = {};
                if ($window.$scope.bkgData.accommodations == undefined) {
                    return;
                }

                var accommodation = $window.$scope.bkgData.accommodations[0];
                var bkgData = $window.$scope.bkgData;

                //Check if payment is ok or not
                if (success) {

                    interactionEvent.event = 'purchase';
                    interactionEvent.currency = 'EUR';
                    interactionEvent.checkout_step = 4;
                    this.fillInteractionEvent(bkgData, accommodation, interactionEvent);
                    interactionEvent.insuranceSelected = bkgData.bookingRequest.insurance != null;
                    if (bkgData.bookingRequest.insurance != null)
                        interactionEvent.insurance_value = bkgData.purchaseResponse.proposalsRequested.insurances[0].totalPrice;
                    else
                        interactionEvent.insurance_value = 0;
                } else {
                    interactionEvent.event = 'payment_error';
                    interactionEvent.currency = 'EUR';
                    interactionEvent.checkout_step = 4;

                }
                const urlParams = new URLSearchParams(window.location.search);
                const paymentType = urlParams.get('paymenttype');
                interactionEvent.payment_method = paymentType;

                console.info("============== show the ecommerceEvent ============");
                console.info(interactionEvent);

                $window.dataLayer.push(
                    interactionEvent
                );
            },
            isDP: function () {
                return $window.$scope.bkgData != undefined && $window.$scope.bkgData.accommodations != undefined && $window.$scope.bkgData.accommodations.any();
            },
            interactionEvent: function (eventName, funnelName, funnelStep) {
                if (this.isDP())
                    this.interactionEventDP(eventName, funnelName, funnelStep);
                else
                    this.interactionEventFlight(eventName, funnelName, funnelStep);
            },
            interactionEventFlight: function (eventName, funnelName, funnelStep) {
                console.log("---Carrefour track Flight ---> interaction Event ::  " + eventName);

                var interactionEvent = {};
                var bkgData = $window.$scope.bkgData;

                if (eventName == 'view_search_results') {
                    interactionEvent.event = 'view_item_list';
                    interactionEvent.currency = 'EUR';
                    //interactionEvent.checkout_step = 0;

                    //get products
                    var products = [];
                    var idx = 1;
                    window.$scope.view.data.products.forEach(item => {
                        var outbound = item.outbound;
                        if (item.inbounds != null) {
                            item.inbounds.forEach(inbound => {
                                var flightNumber = outbound.segments[0].airline + outbound.segments[0].flightNumber;
                                flightNumber += "_" + inbound.segments[0].airline + inbound.segments[0].flightNumber;
                                products.push({
                                    index: idx,
                                    item_id: outbound.key + '_' + inbound.key,
                                    item_category: "Vol",
                                    item_name: flightNumber,
                                    location_id: $window.$scope.view.data.request.itineraries[0].destination,
                                    price: inbound.proposal.averageFare.value,
                                    value: inbound.proposal.totalFare.value,
                                });
                                idx++;
                            })
                        } else {
                            var flightNumber = outbound.segments[0].airline + outbound.segments[0].flightNumber;
                            products.push({
                                index: idx,
                                item_id: outbound.key,
                                item_category: "Vol",
                                item_name: flightNumber,
                                location_id: $window.$scope.view.data.request.itineraries[0].destination,
                                price: outbound.proposal.averageFare.value,
                                value: outbound.proposal.totalFare.value,
                            });
                            idx++;
                        }
                    });

                    interactionEvent.items = products;
                }

                if (eventName == 'select_product') {
                    var bkgInfo = $window.$scope.bkgArgs;
                    var flightNumber = bkgInfo.outbound.segments[0].airline + bkgInfo.outbound.segments[0].flightNumber;
                    var itemid = "";
                    var totalPrice = 0;
                    var avgPrice = 0;
                    if (bkgInfo.inbound) {
                        flightNumber += "_" + bkgInfo.inbound.segments[0].airline + bkgInfo.inbound.segments[0].flightNumber;
                        itemid = bkgInfo.outbound.key + '_' + bkgInfo.inbound.key;
                        totalPrice = bkgInfo.inbound.proposal.totalFare.value;
                        avgPrice = bkgInfo.inbound.proposal.averageFare.value;
                    } else {
                        itemid = bkgInfo.outbound.key;
                        totalPrice = bkgInfo.outbound.proposal.totalFare.value;
                        avgPrice = bkgInfo.outbound.proposal.averageFare.value;
                    }

                    interactionEvent.event = 'add_to_cart';
                    interactionEvent.currency = 'EUR';
                    interactionEvent.item_id = itemid;
                    interactionEvent.item_category= "Vol";
                    interactionEvent.item_name = flightNumber;
                    interactionEvent.item_flightType = this.getFlightTypeName($window.$scope.requestSummary.itineraries);
                    interactionEvent.item_flightClass = scopeParser.getCabinClassName($window.$scope.requestSummary.cabinClass);
                    interactionEvent.item_optionsList = [];
                    console.log("1--------------------")
                    console.log(bkgInfo);

                    interactionEvent.page_productsList = [{
                        index: $window.$scope.bkgArgs.purchaseRequest.index,
                        item_id: itemid,
                        item_category: "Vol",
                        item_name: flightNumber,
                        location_id: $window.$scope.view.data.request.itineraries[0].destination,
                        price: avgPrice,
                        value: totalPrice,
                        flightType: this.getFlightTypeName($window.$scope.requestSummary.itineraries),
                        flightClass: scopeParser.getCabinClassName($window.$scope.requestSummary.cabinClass),
                    }];
                    interactionEvent.index = $window.$scope.bkgArgs.purchaseRequest.index;
                    console.log("2--------------------")
                    console.log(bkgInfo);
                    interactionEvent.price = avgPrice;
                    interactionEvent.value = totalPrice;
                }


                if (eventName == 'funnel_step1bis') { // click between p1 and p2

                    interactionEvent.event = 'begin_checkout';
                    interactionEvent.currency = 'EUR';
                    interactionEvent.checkout_step = 1;
                    this.fillInteractionEvent(bkgData, null, interactionEvent);
                }

                if (eventName == 'funnel_step2') {// click between p2 and p3
                    interactionEvent.event = 'selection_insurance';
                    interactionEvent.currency = 'EUR';
                    interactionEvent.checkout_step = 2;
                    this.fillInteractionEvent(bkgData, null, interactionEvent);
                    interactionEvent.insuranceSelected = bkgData.bookingRequest.insurance != null;
                    if (bkgData.bookingRequest.insurance != null)
                        interactionEvent.insurance_value = bkgData.purchaseResponse.proposalsRequested.insurances[0].totalPrice;
                    else
                        interactionEvent.insurance_value = 0;
                }

                if (eventName == 'funnel_step3') {// click between p3 and p4
                    interactionEvent.event = 'selection_payment';
                    interactionEvent.currency = 'EUR';
                    interactionEvent.checkout_step = 3;
                    this.fillInteractionEvent(bkgData, null, interactionEvent);
                    interactionEvent.insuranceSelected = bkgData.bookingRequest.insurance != null;
                    if (bkgData.bookingRequest.insurance != null)
                        interactionEvent.insurance_value = bkgData.purchaseResponse.proposalsRequested.insurances[0].totalPrice;
                    else
                        interactionEvent.insurance_value = 0;
                    interactionEvent.payment_method = bkgData.selectedPayment;
                }

                console.log(" -- interactionEvent -- ");
                console.log(interactionEvent);

                $window.dataLayer.push(
                    interactionEvent
                );
            },
            interactionEventDP: function (eventName, funnelName, funnelStep) {

                console.log("---Carrefour track DP ---> interaction Event ::  " + eventName);
                
                var interactionEvent = {};
                if ($window.$scope.bkgData.accommodations == undefined) {
                    return;
                }
                    
                var accommodation = $window.$scope.bkgData.accommodations[0];
                var bkgData = $window.$scope.bkgData;


                if (eventName == 'funnel_step1' || eventName == 'funnel_step1bis') { // click between p1 and p2
                    
                    interactionEvent.event = 'begin_checkout';
                    interactionEvent.currency = 'EUR';
                    interactionEvent.checkout_step = 1;
                    this.fillInteractionEvent(bkgData, accommodation, interactionEvent);
                }

                if (eventName == 'funnel_step2') {// click between p2 and p3
                    interactionEvent.event = 'selection_insurance';
                    interactionEvent.currency = 'EUR';
                    interactionEvent.checkout_step = 2;
                    this.fillInteractionEvent(bkgData, accommodation, interactionEvent);
                    interactionEvent.insuranceSelected = bkgData.bookingRequest.insurance != null;
                    if (bkgData.bookingRequest.insurance != null)
                        interactionEvent.insurance_value = bkgData.purchaseResponse.proposalsRequested.insurances[0].totalPrice;
                    else
                        interactionEvent.insurance_value = 0;
                }

                if (eventName == 'funnel_step3') {// click between p3 and p4
                    interactionEvent.event = 'selection_payment';
                    interactionEvent.currency = 'EUR';
                    interactionEvent.checkout_step = 3;
                    this.fillInteractionEvent(bkgData, accommodation, interactionEvent);
                    interactionEvent.insuranceSelected = bkgData.bookingRequest.insurance != null;
                    if (bkgData.bookingRequest.insurance != null)
                        interactionEvent.insurance_value = bkgData.purchaseResponse.proposalsRequested.insurances[0].totalPrice;
                    else
                        interactionEvent.insurance_value = 0;
                    interactionEvent.payment_method = bkgData.selectedPayment;
                }

                console.info("============== show the interactionEvent ============");
                console.info(interactionEvent);

                $window.dataLayer.push(
                    interactionEvent
                );
            },

            /*
                Serializes the GTM model and pushes the datalayer
            */
            pushDataLayer: function (action, value) {
            },
            fillInteractionEvent: function (bkgData, accommodation, interactionEvent) {
                if (accommodation) {
                    interactionEvent.item_id = accommodation.accomCode;
                    interactionEvent.item_name = accommodation.accomName;
                    interactionEvent.index = 2;
                    interactionEvent.item_category = "DP";
                    interactionEvent.item_starRating = accommodation.category;
                    interactionEvent.item_travellerRating = accommodation.tripAdvisorScore;
                    interactionEvent.item_optionsList = accommodation.rooms[0].boards[0].generic;
                } else {
                    var flightNumber = bkgData.purchaseResponse.itineraries[0].segments[0].airline.iata + bkgData.purchaseResponse.itineraries[0].segments[0].flightNumber;
                    interactionEvent.item_id = bkgData.purchaseResponse.itineraries[0].key 
                    if (bkgData.purchaseResponse.itineraries.length >= 2) {
                        flightNumber += "_" + bkgData.purchaseResponse.itineraries[1].segments[0].airline.iata + bkgData.purchaseResponse.itineraries[1].segments[0].flightNumber;
                        interactionEvent.item_id += "_" + bkgData.purchaseResponse.itineraries[1].key;
                    }
                    interactionEvent.item_name = flightNumber;
                    interactionEvent.index = bkgData.purchaseRequest.index;
                    interactionEvent.item_category = "Vol";

                    //Flexyinfo
                    interactionEvent.flex_option = bkgData.bookingRequest.flexyId != null;
                    if (bkgData.bookingRequest.flexyId != null)
                        interactionEvent.flex_option_value = bkgData.purchaseResponse.proposalsRequested.flexies[bkgData.flexyId].totalPrice;
                    else
                        interactionEvent.flex_option_value = "";
                }

                interactionEvent.items = [{ "product": this.getProduct(bkgData, accommodation) }];
                interactionEvent.price = bkgData.totalBasketPrice() / scopeParser.tryGetPaxNumber();
                interactionEvent.value = bkgData.totalBasketPrice();

                var bagDeparture = [];
                bagDeparturePrice = 0;
                var bagReturn = [];
                bagReturnPrice = 0;
                bkgData.bookingRequest.passengers.forEach((pax) => {
                    if (pax.baggages) {
                        if (pax.baggages.length >= 1) {
                            bagDeparture.push(pax.baggages[0].quantity);
                            bagDeparturePrice += pax.baggages[0].totalPrice.value;
                        }
                        if (pax.baggages.length == 2) {
                            bagReturn.push(pax.baggages[1].quantity);
                            bagReturnPrice += pax.baggages[1].totalPrice.value;
                        }
                    }
                });
                interactionEvent.departure_luggage = bagDeparture.join(" | ");
                interactionEvent.departure_luggage_value = bagDeparturePrice;
                interactionEvent.return_luggage = bagReturn.join(" | ");
                interactionEvent.return_luggage_value = bagReturnPrice;

                if (window.sessionStorage.getItem('selectedSeats_' + $scope.bkgData.purchaseResponse.purchaseId) != null) {
                    var seatmapObj = Object.values(JSON.parse(window.sessionStorage.getItem('selectedSeats_' + $scope.bkgData.purchaseResponse.purchaseId)))
                    interactionEvent.selected_seats = seatmapObj.any();
                } else {
                    interactionEvent.selected_seats = false;
                }
                interactionEvent.item_flightType = this.getFlightTypeName(bkgData.purchaseResponse.itineraries);
                interactionEvent.item_flightClass = scopeParser.getCabinClassName($window.$scope.bkgData.request.cabinClass);
            },
            getProduct: function (bkgData, accommodation) {
                var ret = {};

                if (accommodation != null) {
                    var hotelSummary = JSON.parse(this.getCookie('FlightHotelFormSummary'));

                    ret.product_ID = accommodation.accomCode;
                    ret.product_name = accommodation.accomName;
                    ret.product_cat = "DP";
                    ret.product_duration = accommodation.nightNumber;
                    ret.product_numberRooms = accommodation.rooms.length;
                    ret.product_pensionType = accommodation.rooms[0].boards[0].generic;
                    ret.product_starRating = accommodation.category;
                    ret.product_checkinDate = new Date(accommodation.from).toLocaleDateString('fr-fr');
                    ret.product_checkoutDate = new Date(accommodation.to).toLocaleDateString('fr-fr');
                    ret.product_leadtime = parseInt((new Date(accommodation.from) - new Date()) / (1000 * 60 * 60 * 24));
                    ret.product_childrenAge = hotelSummary.rooms[0].childrenAge;
                    ret.product_travelersAdult = scopeParser.tryGetAdultNumber();
                    ret.product_travelersChild = scopeParser.getChildNumber() + scopeParser.getInfantNumber();
                } else {
                    var flightNumber = bkgData.purchaseResponse.itineraries[0].segments[0].airline.iata + bkgData.purchaseResponse.itineraries[0].segments[0].flightNumber;
                    ret.product_ID = bkgData.purchaseResponse.itineraries[0].key;
                    if (bkgData.purchaseResponse.itineraries.length >= 2) {
                        flightNumber += "_" + bkgData.purchaseResponse.itineraries[1].segments[0].airline.iata + bkgData.purchaseResponse.itineraries[1].segments[0].flightNumber;
                        ret.product_ID += bkgData.purchaseResponse.itineraries[1].key;
                        ret.product_duration = parseInt((new Date(bkgData.purchaseResponse.itineraries[1].date) - new Date(bkgData.purchaseResponse.itineraries[0].date)) / (1000 * 60 * 60 * 24));
                    }
                    ret.product_name = flightNumber;
                    ret.product_cat = "Vol";
                    ret.product_childrenAge = "";
                    ret.product_travelersAdult = scopeParser.tryGetAdultNumber();
                    ret.product_travelersChild = scopeParser.getChildNumber();
                    ret.product_travelersBaby = scopeParser.getInfantNumber();
                    ret.product_leadtime = parseInt((new Date(bkgData.purchaseResponse.itineraries[0].date) - new Date()) / (1000 * 60 * 60 * 24));
                }
                
                ret.product_price = scopeParser.round(bkgData.totalBasketPrice() / scopeParser.tryGetPaxNumber(), 2);
                ret.product_catalogue_price = scopeParser.round(bkgData.totalBasketPrice(), 2);
                ret.product_position = bkgData.purchaseRequest.index;
                ret.product_destinationCountry = bkgData.purchaseResponse.itineraries[0].destination.countryName;
                ret.product_destinationCity = bkgData.purchaseResponse.itineraries[0].destination.cityName;
                ret.product_departureAirport = bkgData.purchaseResponse.itineraries[0].origin.airportName;
                ret.product_departureCity = bkgData.purchaseResponse.itineraries[0].origin.cityName;
                ret.product_arrivalAirport = bkgData.purchaseResponse.itineraries[0].destination.airportName;
                ret.product_airlineDeparture = $window.$scope.bkgData.purchaseResponse.itineraries[0].marketingCarrier.name;
                if (bkgData.purchaseResponse.itineraries.length >= 2) {
                    ret.product_airlineArrival = $window.$scope.bkgData.purchaseResponse.itineraries[1].marketingCarrier.name;
                    ret.product_returningDate = new Date(bkgData.purchaseResponse.itineraries[1].date).toLocaleDateString('fr-fr');
                } else {
                    ret.product_airlineArrival = "";
                    ret.product_returningDate = "";
                }
                
                ret.product_arrivalCity = bkgData.purchaseResponse.itineraries[0].destination.cityName;
                ret.product_departureDate = new Date(bkgData.purchaseResponse.itineraries[0].date).toLocaleDateString('fr-fr');
                
                ret.product_travelersAdult = scopeParser.tryGetAdultNumber();
                ret.product_travelersChild = scopeParser.getChildNumber() + scopeParser.getInfantNumber();

                
                ret.product_flightType = this.getFlightTypeName(bkgData.purchaseResponse.itineraries);
                ret.product_cabinType = scopeParser.getCabinClass();

                ret.product_flightDurationDeparture = bkgData.purchaseResponse.itineraries[0].itineraryTime.value;
                if (bkgData.purchaseResponse.itineraries.length >= 2)
                    ret.product_flightDurationArrival = bkgData.purchaseResponse.itineraries[1].itineraryTime.value;
                else
                    ret.product_flightDurationArrival = "";
                ret.product_departureAirportCode = bkgData.purchaseResponse.itineraries[0].origin.airportIata;
                ret.product_arrivalAirportCode = bkgData.purchaseResponse.itineraries[0].destination.airportIata;

                //selected inssurance
                var inssurance = basketHelper.getInsurancePriceDetails($scope.siteType, $scope.bkgData.bookingRequest.insurance, $scope.bkgData.purchaseResponse.proposalsRequested.insurances);
                if (inssurance.any())
                    ret.product_insuranceAmount = basketHelper.getInsurancePriceDetails($scope.siteType, $scope.bkgData.bookingRequest.insurance, $scope.bkgData.purchaseResponse.proposalsRequested.insurances)[0].totalPrice;
                else
                    ret.product_insuranceAmount = 0;

                ret.product_price_folder = 0;
                ret.product_quantity = 1;
                ret.product_price_wt_ass_folder = scopeParser.round(bkgData.totalBasketPrice(), 2);

                var taxes = scopeParser.round(scopeParser.tryGetTaxAmount(), 2);
                ret.product_price_taxes = taxes;
                ret.product_price_wt_ass_folder_and_taxes = scopeParser.round(bkgData.totalBasketPrice() - taxes, 2);

                return ret;
            },
            getFlightTypeName: function (itineraries) {
                if (itineraries.length == 1)
                    return "Aller-Simple";
                if (itineraries.length == 2)
                    return "Aller-Retour";
                if (itineraries.length > 3)
                    return "MultiDesti";
            },
            sha256: function sha256(ascii) {
                function rightRotate(value, amount) {
                    return (value >>> amount) | (value << (32 - amount));
                };

                var mathPow = Math.pow;
                var maxWord = mathPow(2, 32);
                var lengthProperty = 'length'
                var i, j; // Used as a counter across the whole file
                var result = ''

                var words = [];
                var asciiBitLength = ascii[lengthProperty] * 8;

                //* caching results is optional - remove/add slash from front of this line to toggle
                // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
                // (we actually calculate the first 64, but extra values are just ignored)
                var hash = sha256.h = sha256.h || [];
                // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
                var k = sha256.k = sha256.k || [];
                var primeCounter = k[lengthProperty];
                /*/
                var hash = [], k = [];
                var primeCounter = 0;
                //*/

                var isComposite = {};
                for (var candidate = 2; primeCounter < 64; candidate++) {
                    if (!isComposite[candidate]) {
                        for (i = 0; i < 313; i += candidate) {
                            isComposite[i] = candidate;
                        }
                        hash[primeCounter] = (mathPow(candidate, .5) * maxWord) | 0;
                        k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0;
                    }
                }

                ascii += '\x80' // Append Ƈ' bit (plus zero padding)
                while (ascii[lengthProperty] % 64 - 56) ascii += '\x00' // More zero padding
                for (i = 0; i < ascii[lengthProperty]; i++) {
                    j = ascii.charCodeAt(i);
                    if (j >> 8) return; // ASCII check: only accept characters in range 0-255
                    words[i >> 2] |= j << ((3 - i) % 4) * 8;
                }
                words[words[lengthProperty]] = ((asciiBitLength / maxWord) | 0);
                words[words[lengthProperty]] = (asciiBitLength)

                // process each chunk
                for (j = 0; j < words[lengthProperty];) {
                    var w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
                    var oldHash = hash;
                    // This is now the undefinedworking hash", often labelled as variables a...g
                    // (we have to truncate as well, otherwise extra entries at the end accumulate
                    hash = hash.slice(0, 8);

                    for (i = 0; i < 64; i++) {
                        var i2 = i + j;
                        // Expand the message into 64 words
                        // Used below if 
                        var w15 = w[i - 15], w2 = w[i - 2];

                        // Iterate
                        var a = hash[0], e = hash[4];
                        var temp1 = hash[7]
                            + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
                            + ((e & hash[5]) ^ ((~e) & hash[6])) // ch
                            + k[i]
                            // Expand the message schedule if needed
                            + (w[i] = (i < 16) ? w[i] : (
                                w[i - 16]
                                + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) // s0
                                + w[i - 7]
                                + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10)) // s1
                            ) | 0
                            );
                        // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
                        var temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
                            + ((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); // maj

                        hash = [(temp1 + temp2) | 0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
                        hash[4] = (hash[4] + temp1) | 0;
                    }

                    for (i = 0; i < 8; i++) {
                        hash[i] = (hash[i] + oldHash[i]) | 0;
                    }
                }

                for (i = 0; i < 8; i++) {
                    for (j = 3; j + 1; j--) {
                        var b = (hash[i] >> (j * 8)) & 255;
                        result += ((b < 16) ? 0 : '') + b.toString(16);
                    }
                }
                return result;
            }
        }
    }]);
